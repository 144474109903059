import React from 'react';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { NavLink } from 'react-router-dom';
import Apartment1 from '../../assets/apartment1.jpg';
import Metropolitan from '../../assets/metropolitan.svg';
import Avatar from '../../assets/avatar.png';
import Whatsapp from '../../assets/Whatsapp.svg';

export default function ApartmentCard() {
  return (
    <NavLink to="/apartment-details" className="apartmentCardContainer">
      <div className="apartmentCardImageDiv">
        <img src={Apartment1} alt="Apartment1" />
        <div className="apartmentCardVerifiedTag">
          <p>DLD verified</p>
        </div>
        <div className="apartmentCardIconsDiv">
          <div className="apartmentCardIcons">
            <ReportGmailerrorredOutlinedIcon fontSize="small" sx={{ color: '#FF3A3A' }} />
          </div>
          <div className="apartmentCardIcons">
            <FavoriteBorderOutlinedIcon fontSize="small" sx={{ color: '#6F6F6F' }} />
          </div>
        </div>
        <div className="apartmentCardTotalImageTag">
          <ImageOutlinedIcon sx={{ color: '#fff' }} />
          <p>10+</p>
        </div>
      </div>
      <div className="apartmentCardContentDiv">
        <div className="apartmentCardTitle">
          <h3>AED 4,700,000</h3>
        </div>
        <div className="apartmentCardDesp">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna Lorem{' '}
          </p>
        </div>
        <div className="apartmentCardSpecsDiv">
          <div className="apartmentCardSpecsTag">
            <div className="apartmentCardSpecsIcon">
              <BedOutlinedIcon sx={{ color: '#919B9B' }} />
            </div>
            <div className="apartmentCardSpecsText">4 Bedrooms</div>
          </div>
          <div className="apartmentCardSpecsTag">
            <div className="apartmentCardSpecsIcon">
              <BathtubOutlinedIcon sx={{ color: '#919B9B' }} />
            </div>
            <div className="apartmentCardSpecsText">5 Bathrooms</div>
          </div>
          <div className="apartmentCardSpecsTag">
            <div className="apartmentCardSpecsIcon">
              <SquareFootOutlinedIcon sx={{ color: '#919B9B' }} />
            </div>
            <div className="apartmentCardSpecsText">3,243 sq. ft</div>
          </div>
          <div className="apartmentCardSpecsTag">
            <div className="apartmentCardSpecsIcon">
              <SquareOutlinedIcon sx={{ color: '#919B9B' }} />
            </div>
            <div className="apartmentCardSpecsText">863 AED/sq.ft</div>
          </div>
        </div>
        <div className="apartmentCardInfoDiv">
          <div className="apartmentCardInfoTag">
            <div className="apartmentCardInfoAvatar">
              <img src={Metropolitan} alt="Metropolitan" />
            </div>
            <div className="apartmentCardInfoText">
              <h4>Metropolitan Capital</h4>
              <p>Company</p>
            </div>
          </div>
          <div className="apartmentCardInfoTag">
            <div className="apartmentCardInfoAvatar">
              <img src={Avatar} alt="Avatar" />
            </div>
            <div className="apartmentCardInfoText">
              <h4>Raghad Rabah</h4>
              <p>Agent</p>
            </div>
          </div>
        </div>
        <div className="apartmentCardContactDiv">
          <div
            className="apartmentCardContactTag whatsappTag"
            style={{
              border: '1px solid #47B45F',
            }}
          >
            <div className="apartmentCardContactIcon">
              <img style={{ width: '25px' }} src={Whatsapp} alt="whatsapp" />
            </div>
            <p style={{ color: '#47B45F' }}>WhatsApp Agent</p>
          </div>
          <div
            className="apartmentCardContactTag"
            style={{
              border: '1px solid #ff3a3a',
            }}
          >
            <div className="apartmentCardContactIcon">
              <CallOutlinedIcon sx={{ color: '#ff3a3a' }} />
            </div>
            <p style={{ color: '#ff3a3a' }}>Call Agent</p>
          </div>
          <div
            className="apartmentCardContactTag"
            style={{
              border: '1px solid #0E88FA',
            }}
          >
            <div className="apartmentCardContactIcon">
              <EmailOutlinedIcon sx={{ color: '#0E88FA' }} />
            </div>
            <p style={{ color: '#0E88FA' }}>Send an email</p>
          </div>
        </div>
      </div>
    </NavLink>
  );
}
