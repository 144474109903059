import React from 'react';
import '../../css/LandingPage/HeroSection.css';
import Search from '../../assets/Search.svg';
import WhatsappFloattingIcon from '../../assets/WhatsappFloattingIcon.svg';

export default function HeroSection() {
  return (
    <div className="heroSectionContainer">
      <div className="imgdiv">
        <div className="contentContainer">
          <div className="heroTitleSmall"> Welocome To </div>
          <div className="heroTitleLong"> Long Stay </div>
          <div className="heroTitleLong2"> Dubai </div>
          <p className="heroDes">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
          </p>
          <div className="contactButton">
            <div className="con"> Contact Us </div>
          </div>
        </div>
        <div className="searchContainer searchContainerShadow ">
          <div className="searchbar">
            <input
              className="searchBarInput"
              placeholder="Enter a Neighborhood"
              id="Neighborhood"
            />
            <div className="barImg">
              <img src={Search} alt="SerchIcon" />
            </div>
          </div>
          <div className="searchMenu">
            <div className="dropDown">
              <select className="barContentOption">
                <option value="type1">Unit Type</option>
                <option value="type2">2</option>
                <option value="type3">3</option>
              </select>
            </div>
            <div className="dropDown">
              <select className="barContentOption">
                <option value="beds1">Beds</option>
                <option value="beds1">2 beds</option>
                <option value="beds1">3 beds</option>
              </select>
            </div>
            <div className="dropDown">
              <select className="barContentOption">
                <option value="Price1">Prices</option>
                <option value="Price2"> Prices 1</option>
                <option value="Price3"> Prices 2</option>
              </select>
            </div>
            <div className="searchButton">Search</div>
          </div>
        </div>
      </div>

      <div className="whatsappFlotting">
        <img src={WhatsappFloattingIcon} alt="" />
      </div>
    </div>
  );
}
