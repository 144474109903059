// Init
import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { MenuItem } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import Navlog from '../../assets/Navlogo.png';

// Component
export default function Navbar(Props) {
  const { isTrue } = Props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {isTrue ? (
        <div className="navbarContainerProps">
          <div className="Imgdiv">
            <img src={Navlog} alt="logo" />
          </div>
          <div className="menuButtondiv">
            <div className="navbarMenu">
              <NavLink to="/" className="navBarMenuButtonProps">
                Home
              </NavLink>
              <NavLink to="/aboutus" className="navBarMenuButtonProps">
                About Us
              </NavLink>
              <NavLink to="/registered-apartments" className="navBarMenuButtonProps">
                Registered Apartments
              </NavLink>
              <NavLink to="/contactus" className=" navBarMenuButtonProps navBarContactButtonProps">
                Contact Us
              </NavLink>
            </div>
            <div className="buttonLink">
              <button type="submit" className="navbarButtonProps">
                Sign Up
              </button>
              <button type="submit" className="navbarButton2">
                macworldubai.com
              </button>
            </div>
          </div>
          <div className="MenuPopupState">
            <Button
              id="fade-button"
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MenuTwoToneIcon />
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={handleClose}>
                {' '}
                <NavLink to="/" className="navBarMenuButton">
                  {' '}
                  Home
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {' '}
                <NavLink to="/aboutus" className="navBarMenuButton">
                  {' '}
                  About Us{' '}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {' '}
                <NavLink to="/registered-apartments" className="navBarMenuButton">
                  {' '}
                  Registered Apartments{' '}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {' '}
                <NavLink to="/contactus" className="navBarMenuButton">
                  {' '}
                  Contact Us
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {' '}
                <NavLink to="/apartment-details" className="navBarMenuButton">
                  {' '}
                  Sign Up{' '}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {' '}
                <NavLink className="navBarMenuButton">Thamaclinks.com </NavLink>
              </MenuItem>
            </Menu>
          </div>
        </div>
      ) : (
        <div className="navbarContainer">
          <NavLink to="/">
            <div className="Imgdiv">
              <img src={Navlog} alt="logo" />
            </div>
          </NavLink>

          <div className="menuButtondiv">
            <div className="navbarMenu">
              <NavLink to="/" className="navBarMenuButton">
                Home
              </NavLink>
              <NavLink to="/aboutus" className="navBarMenuButton">
                About Us
              </NavLink>
              <NavLink to="/registered-apartments" className="navBarMenuButton">
                Registered Apartments
              </NavLink>
              <NavLink to="/contactus" className="navBarMenuButton">
                Contact Us
              </NavLink>
            </div>
            <div className="buttonLink">
              <button type="submit" className="navbarButton">
                Sign Up
              </button>
              <button type="submit" className="navbarButton2">
                macworldubai.com
              </button>
            </div>
          </div>
          <div className="MenuPopupState">
            <Button
              id="fade-button"
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{ background: 'red' }}
            >
              <MenuTwoToneIcon />
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={handleClose}>
                {' '}
                <NavLink to="/" className="navBarMenuButton">
                  {' '}
                  Home
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {' '}
                <NavLink to="/aboutus" className="navBarMenuButton">
                  {' '}
                  About Us{' '}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {' '}
                <NavLink to="/registered-apartments" className="navBarMenuButton">
                  {' '}
                  Registered Apartments{' '}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {' '}
                <NavLink to="/contactus" className="navBarMenuButton">
                  {' '}
                  Contact Us
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {' '}
                <NavLink to="/apartment-details" className="navBarMenuButton">
                  {' '}
                  Sign Up{' '}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {' '}
                <NavLink className="navBarMenuButton">Thamaclinks.com </NavLink>
              </MenuItem>
            </Menu>
          </div>
        </div>
      )}
    </div>
  );
}
