import React from 'react';
import AboutImg from '../../assets/AboutImg.svg';

export default function AboutSection() {
  return (
    <div className="aboutContainer">
      <div className="aboutTextContainer">
        <div className="aboutTitle">What We Do</div>
        <div className="aboutDes">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna
        </div>
        <div className="readMoreButton"> Read More</div>
      </div>
      <div className="aboutImg">
        <img src={AboutImg} alt="" />
      </div>
    </div>
  );
}
