import React, { useRef, useState } from 'react';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ContactUsImg from '../../assets/ContactUsImg.svg';
// import ContactScreenImg from '../../assets/ContactScreenImg.svg';

export default function ContactUsSection(Props) {
  const { istrue } = Props;
  const form = useRef();
  const [formData, setFormData] = useState({
    purpose: '',
    username: '',
    email: '',
    description: '',
  });
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_doxmfzu', 'template_7mvfnk4', form.current, 'mfwKRMHkIDBKdrQUG').then(
      (result) => {
        console.log(result.text);
        toast.success('submitted');
      },
      (error) => {
        console.log(error.text);
        toast.error('error');
      },
    );
  };
  return (
    <div>
      {istrue ? (
        <div className="contactUsContainerProps">
          <div className="contactUsContextContainerProps">
            <div className="getInToContentContainerProps">
              <div className="getInToTitle"> Get In Touch </div>
              <div className="getinToDesProps">
                We are here to help answer any questions you may have about our platform and
                community. Reach out to us through our contact form
              </div>
            </div>
            <form className="formContainerProps" ref={form} onSubmit={sendEmail}>
              <div className="purposeContainerProps">
                <p className="purposeLabel">Select Your Purpose</p>
                <select
                  className="purposeDropDown"
                  id="DropDown"
                  name="purpose"
                  value={formData.purpose}
                  onChange={handleChange}
                >
                  <option value="Select">Select</option>
                  <option value="Visit">Visit</option>
                  <option value="Working">Working</option>
                  <option value="Working">Study</option>
                </select>
              </div>
              <div className="nameContainerProps">
                <p className="purposeLabe2">Name</p>
                <input
                  type="text"
                  placeholder="Enter your name"
                  className="inputField"
                  id="Name"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>
              <div className="emailContainerProps">
                <p className="purposeLabe2">Email</p>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="inputField"
                  id="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />{' '}
              </div>
              <div className="desContainerProps">
                <p className="purposeLabe2">Description</p>
                <input
                  type="text"
                  placeholder="Enter your description"
                  className="inputField"
                  id="Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>
              <div className="submitButtonDivProps">
                <button type="submit" className="submitButton">
                  {' '}
                  Submit
                  <ArrowRightAltIcon />
                </button>
              </div>
            </form>

            <div className="getInToContainerProps">
              <div className="phoneContainer">
                <div className="getInToIcon">
                  <RingVolumeIcon />
                </div>
                <div className="phoneText">
                  Call Us <br />
                  +1 587-707-1443
                </div>
              </div>
              <div className="gmailContainer">
                <div className="getInToIcon">
                  <MarkEmailUnreadOutlinedIcon />
                </div>
                <div className="phoneText">
                  Gmail
                  <br />
                  xyx@gmail.com
                </div>
              </div>

              <div className="addressContainer">
                <div className="getInToIcon">
                  <LocationOnIcon />
                </div>
                <div className="phoneText">
                  Address
                  <br />
                  11 rue Marie de Médicis
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="contactUsContainer">
          {/* <div className="getInToContentContainerMob">
            <div className="getInToTitle"> Get In Touch </div>
            <div className="getinToDes">
              We are here to help answer any questions you may have about our platform and
              community. Reach out to us through our contact form
            </div>
          </div> */}
          <div className="contactUsContextContainer">
            <form className="formContainer" ref={form} onSubmit={sendEmail}>
              <div className="purposeContainer">
                <p className="purposeLabel">Select Your Purpose</p>
                <select
                  className="purposeDropDown"
                  id="DropDown"
                  name="purpose"
                  value={formData.purpose}
                  onChange={handleChange}
                >
                  <option value="Select">Select</option>
                  <option value="Visit">Visit</option>
                  <option value="Working">Working</option>
                </select>
              </div>
              <div className="nameContainer">
                <p className="purposeLabe2">Name</p>
                <input
                  type="text"
                  placeholder="Enter your name"
                  className="inputField"
                  id="Name"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>
              <div className="emailContainer">
                <p className="purposeLabe2">Email</p>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="inputField"
                  id="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />{' '}
              </div>
              <div className="desContainer">
                <p className="purposeLabe2">Description</p>
                <input
                  type="text"
                  placeholder="Enter your description"
                  className="inputField"
                  id="Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>
              <div className="submitButtonDiv">
                <button type="submit" className="submitButton">
                  {' '}
                  Submit
                  <ArrowRightAltIcon />
                </button>
              </div>
            </form>

            <div className="getInToContainer">
              <div className="getInToContentContainer">
                <div className="getInToTitle"> Get In Touch </div>
                <div className="getinToDes">
                  We are here to help answer any questions you may have about our platform and
                  community. Reach out to us through our contact form
                </div>
              </div>
              <div className="phoneContainer">
                <div className="getInToIcon">
                  <RingVolumeIcon />
                </div>
                <div className="phoneText">
                  Call Us <br />
                  +1 587-707-1443
                </div>
              </div>
              <div className="gmailContainer">
                <div className="getInToIcon">
                  <MarkEmailUnreadOutlinedIcon />
                </div>
                <div className="phoneText">
                  Gmail
                  <br />
                  xyx@gmail.com
                </div>
              </div>

              <div className="addressContainer">
                <div className="getInToIcon">
                  <LocationOnIcon />
                </div>
                <div className="phoneText">
                  Address
                  <br />
                  11 rue Marie de Médicis
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
