import React from 'react';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Apartment from '../../assets/detailApartment.png';
import Carousel1 from '../../assets/carousel1.png';
import Carousel2 from '../../assets/carousel2.png';
import Carousel3 from '../../assets/carousel3.png';
// import Apartment from '../../assets/apartment.png'
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 768, min: 600 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  miniMobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1.3,
    slidesToSlide: 1, // optional, default to 1.
  },
};
export default function HeroSlider() {
  const screenWidth = window.innerWidth;
  return (
    <div className="heroSliderContainer">
      <div className="heroSliderTitleDiv">
        <h1>Buy, AED 1.37M · 1BHK Apartment, Dubai, Business Bay </h1>
        <div className="heroSliderIconsDiv">
          <div className="heroSliderIcons">
            <ReportGmailerrorredOutlinedIcon
              fontSize={screenWidth > 1024 ? 'large' : 'medium'}
              sx={{ color: '#FF3A3A' }}
            />
          </div>
          <div className="heroSliderIcons">
            <FavoriteBorderOutlinedIcon
              fontSize={screenWidth > 1024 ? 'large' : 'medium'}
              sx={{ color: '#6F6F6F' }}
            />
          </div>
        </div>
      </div>
      <div className="heroSliderImagesContainer">
        <div className="heroSliderLargeImage">
          <img src={Apartment} alt="apartment" />
        </div>
        <div className="heroSliderCarouselDiv">
          <Carousel className="heroSliderCarousel" responsive={responsive}>
            <div className="heroSliderCarouselItems">
              <img src={Carousel1} alt="Carousel1" />
            </div>
            <div className="heroSliderCarouselItems">
              <img src={Carousel2} alt="Carousel1" />
            </div>
            <div className="heroSliderCarouselItems">
              <img src={Carousel3} alt="Carousel1" />
            </div>
            <div className="heroSliderCarouselItems">
              <img src={Carousel1} alt="Carousel1" />
            </div>
            <div className="heroSliderCarouselItems">
              <img src={Carousel2} alt="Carousel1" />
            </div>
            <div className="heroSliderCarouselItems">
              <img src={Carousel3} alt="Carousel1" />
            </div>
            <div className="heroSliderCarouselItems">
              <img src={Carousel1} alt="Carousel1" />
            </div>
            <div className="heroSliderCarouselItems">
              <img src={Carousel2} alt="Carousel1" />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
