/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Grid } from '@mui/material';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import CallToActionOutlinedIcon from '@mui/icons-material/CallToActionOutlined';
import GoogleMapReact from 'google-map-react';
import Whatsapp from '../../assets/Whatsapp.svg';
import Metropolitan from '../../assets/metropolitan.svg';
import Avatar from '../../assets/avatar.png';

function AnyReactComponent({ text }) {
  return <div>{text}</div>;
}

export default function ApartmentInfo() {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  return (
    <div className="apartmentInfoContainer">
      <div className="apartmentInfoContactInfo">
        <div className="apartmentInfoPriceDiv">
          <h2>AED 4,700,000</h2>
        </div>
        <div className="apartmentInfoContact">
          <div className="apartmentInfoContactLinks">
            <div className="apartmentInfoLinkBtn apartmentInfoCall">
              <PhoneIcon sx={{ color: '#fff' }} />
              <p>Call Agent</p>
            </div>
            <div className="apartmentInfoLinkBtn apartmentInfoWhatsapp">
              <div className="apartmentInfoLinkIcon">
                <img className="apartmentInfoLinkIconImg" src={Whatsapp} alt="Whatsapp" />
              </div>
              <p>WhatsApp Agent</p>
            </div>
            <div className="apartmentInfoLinkBtn apartmentInfoEmail">
              <EmailOutlinedIcon sx={{ color: '#0e88fa' }} />
              <p>Send an email</p>
            </div>
          </div>
          <div className="apartmentInfoOwnerDiv">
            <div className="apartmentInfoOwnerTag">
              <div className="apartmentInfoOwnerAvatar">
                <img src={Metropolitan} alt="Metropolitan" />
              </div>
              <div className="apartmentInfoOwnerText">
                <h3>Metropolitan Capital</h3>
                <p>Company</p>
              </div>
            </div>
            <div className="apartmentInfoOwnerTag">
              <div className="apartmentInfoOwnerAvatar">
                <img src={Avatar} alt="Avatar" />
              </div>
              <div className="apartmentInfoOwnerText">
                <h3>Raghad Rabah</h3>
                <p>Agent</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="apartmentInfoFurnishedDiv">
        <h4>Genuine Resale | Investors Deal | Hot Deal</h4>
        <h2>Apartment · 1 Bedroom · 2 Bathrooms · Furnished</h2>
        <Grid container spacing={1} className="apartmentInfoSpecsGridContainer">
          <Grid item xs={12} sm={5.8} md={3.8} className="apartmentInfoSpecsGrid">
            <div className="apartmentInfoSpecsGridItem">
              <BedOutlinedIcon />
              <p>4 Bedrooms</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="apartmentInfoSpecsGrid">
            <div className="apartmentInfoSpecsGridItem">
              <BathtubOutlinedIcon />
              <p>5 Bathrooms</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="apartmentInfoSpecsGrid">
            <div className="apartmentInfoSpecsGridItem">
              <SquareFootOutlinedIcon />
              <p>3,243 sq. ft</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="apartmentInfoSpecsGrid">
            <div className="apartmentInfoSpecsGridItem">
              <SquareOutlinedIcon />
              <p>863 AED/sq.ft</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="apartmentInfoSpecsGrid">
            <div className="apartmentInfoSpecsGridItem">
              <SquareOutlinedIcon />
              <p>Ref: MS-6310</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="apartmentInfoSpecsGrid">
            <div className="apartmentInfoSpecsGridItem">
              <SquareOutlinedIcon />
              <p>Listed: 1 day ago</p>
            </div>
          </Grid>
        </Grid>
        <h2>This Apartment is represented by Metropolitan Premium Properties</h2>
        <p>
          <span>Metropolitan Premium Properties</span> is part of the UAE's largest real estate
          agencies that chose to partner with houza. <br /> <br />
          Perfectly situated next to downtown and Dubai’s prime economic hubs, THE PARAGON by IGO
          combines sophisticated city style with all of the benefits of trendy community living. An
          upscale lifestyle with more amenities than you could dream up, these apartments are
          created for the future-minded investor who won’t settle for anything but unmatched
          perfection. THE PARAGON smart homes equipped residences, branded fully equipped kitchens.
          Whether you want to work, exercise or socialize – this building has it all.
        </p>
        <div className="apartmentInfoSeeMoreBtn">
          <p>See More</p>
          <ExpandMoreOutlinedIcon sx={{ color: '#FF3A3A' }} />
        </div>
        <h2>Apartment Amenities: This Property includes Central Ac, Concierge Service</h2>
        <div className="apartmentInfoServicesDiv">
          <div className="apartmentInfoServicesTag">
            <CallToActionOutlinedIcon sx={{ color: '#FF3A3A' }} />
            <p>Central AC</p>
          </div>
          <div className="apartmentInfoServicesTag">
            <HandymanOutlinedIcon sx={{ color: '#FF3A3A' }} />
            <p>Concierge Service</p>
          </div>
        </div>
        <h2>Find this property in The Paragon by IGO, Business Bay</h2>
        <div className="apartmentInfoMapDiv">
          <GoogleMapReact
            bootstrapURLKeys={{ key: '' }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
}
