// Init
import React from 'react';

// Importing Components
import Navbar from '../components/comman/Navbar';
import RentSection from '../components/aboutUs/RentSection';
import AboutSection from '../components/aboutUs/AboutSection';
import FAQs from '../components/aboutUs/FAQs';
import Footer from '../components/comman/Footer';

// Home Component
export default function AboutUs() {
  return (
    <>
      <Navbar />
      <RentSection />
      <AboutSection />
      <FAQs />
      <Footer />
    </>
  );
}
