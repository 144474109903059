import React from 'react';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { NavLink } from 'react-router-dom';
import Apartment1 from '../../assets/apartment1.jpg';
import Avatar from '../../assets/avatar.png';
import Metropolitan from '../../assets/metropolitan.svg';
import Whatsapp from '../../assets/Whatsapp.svg';

export default function RecommendedCard() {
  const screenWidth = window.innerWidth;

  return (
    <NavLink to="/apartment-details" className="recommendedCardContainer">
      <div className="recommendedCardImageDiv">
        <img src={Apartment1} alt="Apartment1" />
        <div className="recommendedCardVerifyTag">
          <p>DLD verified</p>
        </div>
        <div className="recommendedCardIconsDiv">
          <div className="recommendedCardIcons">
            <ReportGmailerrorredOutlinedIcon fontSize="small" sx={{ color: '#FF3A3A' }} />
          </div>
          <div className="recommendedCardIcons">
            <FavoriteBorderOutlinedIcon fontSize="small" sx={{ color: '#6F6F6F' }} />
          </div>
        </div>
        <div className="recommendedCardTotalImageTag">
          <ImageOutlinedIcon sx={{ color: '#fff' }} />
          <p>10+</p>
        </div>
      </div>
      <div className="recommendedCardContentDiv">
        <h3>AED 4,700,000</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna Lorem{' '}
        </p>
        <div className="recommendedCardTagsDiv">
          <div className="recommendedCardTags">
            <BedOutlinedIcon
              fontSize={screenWidth > 768 ? 'medium' : 'small'}
              sx={{ color: '#919B9B' }}
            />
            <p>4 Bedrooms</p>
          </div>
          <div className="recommendedCardTags">
            <BathtubOutlinedIcon
              fontSize={screenWidth > 768 ? 'medium' : 'small'}
              sx={{ color: '#919B9B' }}
            />
            <p>5 Bathrooms</p>
          </div>
          <div className="recommendedCardTags">
            <SquareFootOutlinedIcon
              fontSize={screenWidth > 768 ? 'medium' : 'small'}
              sx={{ color: '#919B9B' }}
            />
            <p>3,243 sq. ft</p>
          </div>
          <div className="recommendedCardTags">
            <SquareOutlinedIcon
              fontSize={screenWidth > 768 ? 'medium' : 'small'}
              sx={{ color: '#919B9B' }}
            />
            <p>863 AED/sq.ft</p>
          </div>
        </div>
        <div className="recommendedCardAgentDiv">
          <div className="recommendedCardAgentTag">
            <div className="recommendedCardAgentAvatar">
              <img src={Avatar} alt="avatar" />
            </div>
            <div className="recommendedCardAgentText">
              <h4>Raghad Rabah</h4>
              <p>Agent</p>
            </div>
          </div>
          <div className="recommendedCardAgentTag">
            <div className="recommendedCardAgentAvatar">
              <img src={Metropolitan} alt="Metropolitan" />
            </div>
            <div className="recommendedCardAgentText">
              <h4>Metropolitan..</h4>
              <p>Company</p>
            </div>
          </div>
        </div>
        <div className="recommendedCardContactDiv">
          <button type="submit" className="recommendedCardCallBtn">
            <PhoneIcon fontSize={screenWidth > 768 ? 'medium' : 'small'} />
            <p> Call Agent</p>
          </button>
          <button type="submit" className="recommendedCardWhatsappBtn">
            <img src={Whatsapp} alt="Whatsapp" />
            <p> WhatsApp Agent</p>
          </button>
          <button type="submit" className="recommendedCardMailBtn">
            <EmailOutlinedIcon fontSize={screenWidth > 768 ? 'medium' : 'small'} />
            <p> Send an email</p>
          </button>
        </div>
      </div>
    </NavLink>
  );
}
