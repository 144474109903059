import React from 'react';
import HeroSlider from '../components/apartmentDetails/HeroSlider';
import Navbar from '../components/comman/Navbar';
import Footer from '../components/comman/Footer';
import ApartmentInfo from '../components/apartmentDetails/ApartmentInfo';
import SimilarProperties from '../components/apartmentDetails/SimilarProperties';

export default function ApartmentDetails() {
  return (
    <>
      <Navbar />
      <HeroSlider />
      <ApartmentInfo />
      <SimilarProperties />
      <Footer />
    </>
  );
}
