import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
// import Apartment from '../../assets/apartment.png'

export default function HeroFilter() {
  const screenWidth = window.innerWidth;
  return (
    <div className="heroFilterContainer">
      <div className="heroFilterSubDiv">
        <div className="heroFilterInputContainer">
          <div className="heroFilterSearchDiv">
            <input type="text" placeholder="Enter a Neighborhood" />
            <div className="heroFilterSearchIcon">
              <SearchIcon
                fontSize={screenWidth > 1024 ? 'large' : 'small'}
                sx={{ color: '#ff3a3a' }}
              />
            </div>
          </div>
          <div className="heroFilterDropDownDiv">
            <div className="heroFilterDropDown">
              <select className="heroFilterDropDownSelect">
                <option>Unit Type</option>
                <option>asasa</option>
                <option>asasa</option>
                <option>asasa</option>
              </select>
            </div>
            <div className="heroFilterDropDown">
              <select className="heroFilterDropDownSelect">
                <option>Beds</option>
                <option>asasa</option>
                <option>asasa</option>
                <option>asasa</option>
              </select>
            </div>
            <div className="heroFilterDropDown">
              <select className="heroFilterDropDownSelect">
                <option>Prices</option>
                <option>asasa</option>
                <option>asasa</option>
                <option>asasa</option>
              </select>
            </div>
            <div className="heroFilterSearchBtnDiv">
              <button type="submit" className="heroFilterSearchBtn">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
