// Init
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Routes
import Home from '../containers/Home';
import AboutUs from '../containers/AboutUs';
import RegisterApartment from '../containers/RegisterApartment';
import ApartmentDetails from '../containers/ApartmentDetails';
import ContactUs from '../containers/ContactUs';

function Index() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/registered-apartments" element={<RegisterApartment />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/apartment-details" element={<ApartmentDetails />} />
    </Routes>
  );
}
export default Index;
