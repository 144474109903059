import React from 'react';
import Symtric from '../../assets/SymtricLogo.png';
import Resecurb from '../../assets/ResecurbLogo.png';
import Welytics from '../../assets/WelyticsLogo.png';
import Jiggle from '../../assets/JiggleLogo.png';
import Wishelp from '../../assets/WishelpLogo.png';

export default function LogoSection() {
  return (
    <div className="logoSectionContainer">
      <div className="logosContainer">
        <div className="logoStyle">
          <img src={Symtric} alt="symtric" />
        </div>
        <div className="logoStyle">
          <img src={Resecurb} alt="resecurb" />
        </div>
        <div className="logoStyle">
          <img src={Welytics} alt="welytics" />
        </div>
        <div className="logoStyle">
          <img src={Jiggle} alt="jiggle" />
        </div>
        <div className="logoStyle">
          <img src={Wishelp} alt="wishelp" />
        </div>
      </div>
    </div>
  );
}
