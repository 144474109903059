// Init
import React from 'react';
import KeyIcon from '@mui/icons-material/Key';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AboutSec from '../../assets/aboutsec.png';

// Component
export default function AboutSection() {
  const screenWidth = window.innerWidth;
  return (
    <div className="aboutSectionContainer">
      <div className="aboutSectionImageDiv">
        <img src={AboutSec} alt="RentImage" />
      </div>
      <div className="aboutSectionTextDiv">
        <h4>About,</h4>
        <h3>What We Do</h3>
        <p>
          This company is a perfect platform if you are planning to rent your home or apartment as a
          holiday home for tourists amid growing popularity of Dubai as a holiday destination. You
          can trust us with the cleaning and maintenance of your house both before and after
          tenant’s visits. Thus, you will find the basic services with a blend of modern approach
          for perfect handling and communication with the tenants and easy to go approach for both
          the tenant as well as the owner. <br /> <br /> On the tenant side we assure a comfortable
          and luxurious stay providing you an unforgettable experience for a lifetime and that too
          in an affordable price. The company Letstay rental home Prioritizes customer satisfaction
          and Emphasises on customer communication.
        </p>
        <div className="aboutSectionTextBtnContainer">
          <div className="aboutSectionTextBtnDiv">
            <div className="aboutSectionBtnIcon">
              <KeyIcon
                fontSize={screenWidth > 1024 ? 'large' : 'medium'}
                sx={{ color: '#FF8C39' }}
              />
            </div>
            <button type="submit" className="aboutSectionTextBtn">
              Easy Rental
            </button>
          </div>
          <div className="aboutSectionTextBtnDiv">
            <div className="aboutSectionBtnIcon">
              <CorporateFareIcon
                fontSize={screenWidth > 1024 ? 'large' : 'medium'}
                sx={{ color: '#FF8C39' }}
              />
            </div>
            <button type="submit" className="aboutSectionTextBtn">
              Property Managements
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
