import React from 'react';
import { Grid } from '@mui/material';
import RecommendedCard from './RecommendedCard';

export default function SimilarProperties() {
  return (
    <div className="similarPropertiesContainer">
      <div className="similarPropertiesSubContainer">
        <h2>Similar properties</h2>
        <button type="button">See More</button>
      </div>
      <div className="similarPropertiesCardContainer">
        <Grid className="similarPropertiesGridContainer" container>
          <Grid item xs={12} sm={5.8} md={3.9} className="similarPropertiesGrid">
            <RecommendedCard />
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.9} className="similarPropertiesGrid">
            <RecommendedCard />
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.9} className="similarPropertiesGrid">
            <RecommendedCard />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
