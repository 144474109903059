// Init
import React from 'react';
import Collapse from '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FAQ from '../../assets/faqImage.png';
// Component
export default function FAQs() {
  const screenWidth = window.innerWidth;
  const [open, setOpen] = React.useState('');

  const handleClick = (item) => {
    setOpen(item);
  };
  const listData = [
    {
      index: 1,
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      body: (
        <p className="faqCollapseText">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a mollis est. Nullam vitae
          magna eu velit cursus malesuada vitae id libero.{' '}
        </p>
      ),
    },
    {
      index: 2,
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      body: (
        <div className="faqCollapseText">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a mollis est. Nullam vitae
          magna eu velit cursus malesuada vitae id libero.{' '}
        </div>
      ),
    },
    {
      index: 3,
      title: 'asdsadxccxcxvcxva',
      body: (
        <div className="faqCollapseText">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a mollis est. Nullam vitae
          magna eu velit cursus malesuada vitae id libero.{' '}
        </div>
      ),
    },
    {
      index: 4,
      title: 'last one',
      body: (
        <div className="faqCollapseText">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a mollis est. Nullam vitae
          magna eu velit cursus malesuada vitae id libero.{' '}
        </div>
      ),
    },
  ];
  return (
    <div className="faqContainer">
      <div className="faqSebContainer">
        <div className="faqImageDiv">
          <img src={FAQ} alt="FAQ" />
        </div>
        <div className="faqTextDiv">
          <h4>Q/A</h4>
          <h3>FAQS</h3>
          {listData?.map((item) => {
            return (
              <div className="faqTextDropDown">
                <button
                  className="faqTextTitleDiv"
                  type="submit"
                  onClick={() => {
                    if (open !== item.title) {
                      handleClick(item.title);
                    } else {
                      handleClick('');
                    }
                  }}
                >
                  <div className="faqTextLeftDiv">
                    <div className="faqTextIndexDiv">
                      <h3>{item.index}</h3>
                    </div>
                    <div className="faqTextTitle">
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                  <div className="faqTextIconDiv">
                    {open === item.title ? (
                      <RemoveIcon fontSize={screenWidth > 1024 ? 'large' : 'medium'} />
                    ) : (
                      <AddIcon fontSize={screenWidth > 1024 ? 'large' : 'medium'} />
                    )}
                  </div>
                </button>

                <Collapse
                  className="faqTextCollapse"
                  in={open === item.title}
                  timeout="auto"
                  unmountOnExit
                >
                  <div className="ß">{item.body}</div>
                </Collapse>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
