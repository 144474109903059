import React from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Navlog from '../../assets/Navlogo.png';
import TwitterIcon from '../../assets/TwitterIcon.svg';
import LinkedInIcon from '../../assets/LinkedInIcon.svg';
import WhatsappIcon from '../../assets/WhatsappIcon.svg';
import FacebookIcon from '../../assets/FacebookIcon.svg';

export default function Footer() {
  const screenWidth = window.innerWidth;
  return (
    <div className="footerContainer">
      <div className="contextContainer">
        <div className="textDetailContainer">
          <div className="iconTitleContainer">
            <div className="dubaiLogoHolder">
              <img src={Navlog} alt="DubaiLogo" />
            </div>
            <div className="textTitle"> Long Stay Dubai </div>
          </div>
          <div className="textDes">
            Our initiative is dedicated to promoting global teamwork and knowledge-sharing. Through
            a variety of online tools and platforms, we aim to facilitate seamless communication and
            collaboration
          </div>

          <div className="textMenu">
            <p className="textMenuStyle">Home</p>
            <p className="textMenuStyle">About Us</p>
            <p className="textMenuStyle">Registered Department</p>
            <p className="textMenuStyle">Contact Us</p>
          </div>
        </div>
        <div className="newsLetterContainer">
          <div className="newsLetterTitle"> Newsletter</div>
          <div className="newsLetterMailContiner">
            <span className="mailLogo">
              <EmailOutlinedIcon
                sx={{
                  color: 'black',
                }}
                fontSize={screenWidth > 1024 ? 'medium' : 'small'}
              />
            </span>

            <div className="inputFieldWrapper">
              <input type="text" className=" newsLetterInput" />
            </div>
            <button type="submit" className="subscribeButton">
              {' '}
              Subscribe
            </button>
          </div>
        </div>
      </div>

      <div className="footerBarContainer">
        <div className="footerContentContainer">
          <div className="footerText">
            {' '}
            © 2015-2023
            <div className="footerTextDubai"> Long Stay Dubai </div> All Rights Reserved.{' '}
          </div>
          <div className="footerLogos">
            <img src={FacebookIcon} alt="FacebookIcon" />
            <img src={TwitterIcon} alt="twitterIcon" />
            <img src={LinkedInIcon} alt="LinkedInIcon" />
            <img src={WhatsappIcon} alt="WhatsappIcon" />
          </div>
        </div>
      </div>
    </div>
  );
}
