/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ApartmentCard from './ApartmentCard';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RegisteredList() {
  const [value, setValue] = React.useState(0);
  const numberOfCards = 5;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="registeredListContainer">
      <div className="registeredListContentDiv">
        <h3>Residential Properties for Rent in the UAE</h3>
        <div className="registeredListTabsDiv">
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                position: 'relative',
              }}
            >
              {/* <div className="registeredListFadeDiv" /> */}
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                allowScrollButtonsMobile
                visibleScrollbar
                // variant="scrollable"
                // scrollButtons="auto"
                // aria-label="scrollable auto tabs example"
              >
                <Tab className="registeredListTab" label="Apartment" {...a11yProps(0)} />
                <Tab className="registeredListTab" label="Dubai" {...a11yProps(1)} />
                <Tab className="registeredListTab" label="Villa (2089)" {...a11yProps(2)} />
                <Tab className="registeredListTab" label="Penthouse (231)" {...a11yProps(3)} />
                <Tab className="registeredListTab" label="Land (32)" {...a11yProps(4)} />
                <Tab className="registeredListTab" label="Duplex (120)" {...a11yProps(5)} />
                <Tab
                  className="registeredListTab"
                  label="Hotel Apartment (111)"
                  {...a11yProps(6)}
                />
                <Tab className="registeredListTab" label="Hotel (2)" {...a11yProps(7)} />
                <Tab className="registeredListTab" label="Hotel (1)" {...a11yProps(8)} />
                <Tab
                  className="registeredListTab"
                  label="Hotel Apartment (111)"
                  {...a11yProps(9)}
                />
                <Tab
                  className="registeredListTab"
                  label="Hotel Apartment (111)"
                  {...a11yProps(10)}
                />
              </Tabs>
            </Box>
            <div className="registeredListResultDiv">
              <div className="registeredListResult">
                <h4>28,609 results</h4>
              </div>
              <div className="registeredListRecommendedDiv">
                <select>
                  <option>Recommended</option>
                  <option>4 bed</option>
                  <option>3 bed</option>
                  <option>2 bed</option>
                </select>
              </div>
            </div>
            <CustomTabPanel value={value} index={0}>
              {Array.from({ length: numberOfCards }, (_, index) => (
                <ApartmentCard index={index} />
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {Array.from({ length: numberOfCards }, (_, index) => (
                <ApartmentCard index={index} />
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              {Array.from({ length: numberOfCards }, (_, index) => (
                <ApartmentCard index={index} />
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              {Array.from({ length: numberOfCards }, (_, index) => (
                <ApartmentCard index={index} />
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              {Array.from({ length: numberOfCards }, (_, index) => (
                <ApartmentCard index={index} />
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              {Array.from({ length: numberOfCards }, (_, index) => (
                <ApartmentCard index={index} />
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
              {Array.from({ length: numberOfCards }, (_, index) => (
                <ApartmentCard index={index} />
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={7}>
              {Array.from({ length: numberOfCards }, (_, index) => (
                <ApartmentCard index={index} />
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={8}>
              {Array.from({ length: numberOfCards }, (_, index) => (
                <ApartmentCard index={index} />
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={9}>
              {Array.from({ length: numberOfCards }, (_, index) => (
                <ApartmentCard index={index} />
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={10}>
              {Array.from({ length: numberOfCards }, (_, index) => (
                <ApartmentCard index={index} />
              ))}
            </CustomTabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}
