// Init
import React from 'react';

// Importing Components
import Navbar from '../components/comman/Navbar';
import HeroFilter from '../components/registeredApartments/HeroFilter';
import RegisteredList from '../components/registeredApartments/RegisteredList';
import Footer from '../components/comman/Footer';

// Home Component
export default function RegisterApartment() {
  return (
    <>
      <Navbar />
      <HeroFilter />
      <RegisteredList />
      <Footer />
    </>
  );
}
