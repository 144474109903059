import { Tooltip } from '@mui/material';
import React from 'react';

export default function ViewSectionCard(props) {
  const { description, title } = props;
  const slicedDescription =
    description.length > 250 ? `${description.slice(0, 250)}...` : description;
  // const CardData = [
  //   {
  //     Description:
  //       'I came to the Drip Gym for cool sculpting. Gill was amazing from start to finish. The facility is very clean and the staff followed covid precautions properly, which made me very comfortable. I highly recommend them and will be returning to try out some of their other services.',
  //     Title: 'Monalesa B.',
  //   },

  //   {
  //     Description:
  //       'Was my first time at Drip Gym and it won’t be my last. I went to drip gym today to seek a much-needed boost which I did get. I recommend anyone who is seeking an immune boost or just wanting to work on their overall health contact Drip Gym and make an appointment. Gillayne was as professional as they come and made sure I had the perfect cocktail to fit my needs',
  //     Title: 'Henry Paddington.',
  //   },

  //   {
  //     Description:
  //       'I came to the Drip Gym for cool sculpting. Gill was amazing from start to finish. The facility is very clean and the staff followed covid precautions properly, which made me very comfortable. I highly recommend them and will be returning to try out some of their other services.',
  //     Title: 'Jack M.',
  //   },
  // ];
  return (
    <div className="cardConainter">
      <div className="sliderCard">
        <Tooltip title={description}>
          <div className="cardDes">
            <p>{slicedDescription} </p>
          </div>
        </Tooltip>

        <div className=" cardTitle">
          <h3>{title}</h3>
        </div>
      </div>
    </div>
  );
}
