// Init
import React from 'react';

// Importing Components
import Navbar from '../components/comman/Navbar';
import HeroSection from '../components/LandingPage/HeroSection';
import AboutSection from '../components/LandingPage/AboutSection';
import ViewSection from '../components/LandingPage/ViewSection';
import ContactUsSection from '../components/LandingPage/ContactUsSection';
import LogoSection from '../components/LandingPage/LogoSection';
import Footer from '../components/comman/Footer';

// Home Component
export default function Home() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <AboutSection />
      <ViewSection />
      <ContactUsSection />
      <LogoSection />
      <Footer />
    </>
  );
}
