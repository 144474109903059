// Init
import React from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import MoneyIcon from '@mui/icons-material/Money';
import RentImage from '../../assets/rentAbout.png';

// Component
export default function RentSection() {
  const screenWidth = window.innerWidth;
  return (
    <div className="rentSectionContainer">
      <div className="rentSectionTextDiv">
        <h4>Rent More,</h4>
        <h3>Stress Less</h3>
        <p>
          Concerned about a vacant property, but not getting expected benefits? Join hands with us
          the Letstay rental home company, an established property management services company for
          over 50 years now, with the experience in property and finance management, based in Dubai
          . Reap maximum benefits from all kinds of property be it residential, commercial or
          industrial while staying away from the daily stresses of ownership. Experts from the
          company will ensure an established tenant communication and management while providing our
          clients easy access to their property. Our company not only ensures basic services
          including scheduled maintenance and regular tracking of your property but also settling
          and handling appropriate rent with our financial expertise and that too with least
          possible concern of the owner.
        </p>
        <div className="rentSectionTextBtnContainer">
          <div className="rentSectionTextBtnDiv">
            <div className="rentSectionBtnIcon">
              <HomeRoundedIcon
                fontSize={screenWidth > 1024 ? 'large' : 'medium'}
                sx={{ color: '#FF8C39' }}
              />
            </div>
            <button type="submit" className="rentSectionTextBtn">
              Flexible Appartments
            </button>
          </div>
          <div className="rentSectionTextBtnDiv">
            <div className="rentSectionBtnIcon">
              <MoneyIcon
                fontSize={screenWidth > 1024 ? 'large' : 'medium'}
                sx={{ color: '#FF8C39' }}
              />
            </div>
            <button type="submit" className="rentSectionTextBtn">
              Custom Rates
            </button>
          </div>
        </div>
      </div>
      <div className="rentSectionImageDiv">
        <img src={RentImage} alt="RentImage" />
      </div>
    </div>
  );
}
