import React from 'react';
import Carousel from 'react-multi-carousel';
import ViewSectionCard from './ViewSectionCard';
// import ToggleArrow from '../../assets/ToggleArrow.png';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2.5,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 768, min: 600 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  miniMobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default function ViewSection() {
  return (
    <div className="viewSectionContainer">
      <div className="guestConainter">
        <div className="guestTitle">
          What Our <div className="guestTitle guestTitlecolor">Guests</div> Say
        </div>
        <div className="guestDes">
          {' '}
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor{' '}
        </div>
      </div>
      <div className="mainCardConainter">
        <Carousel
          // centerMode
          arrows={false}
          showDots
          className="mainCardCarousel"
          responsive={responsive}
        >
          <div className="mainCardCarouselItem">
            <ViewSectionCard
              description="I came to the Drip Gym for cool sculpting. Gill was amazing from start to finish. The facility is very clean and the staff followed covid precautions properly, which made me very comfortable. I highly recommend them and will be returning to try out some of their other services."
              title="Monalesa B."
            />
          </div>
          <div className="mainCardCarouselItem">
            <ViewSectionCard
              description="Was my first time at Drip Gym and it won’t be my last. I went to drip gym today to seek a much-needed boost which I did get. I recommend anyone who is seeking an immune boost or just wanting to work on their overall health contact Drip Gym and make an appointment. Gillayne was as professional as they come and made sure I had the perfect cocktail to fit my needs"
              title="Henry Paddington."
            />
          </div>
          <div className="mainCardCarouselItem">
            <ViewSectionCard
              description="I came to the Drip Gym for cool sculpting. Gill was amazing from start to finish. The facility is very clean and the staff followed covid precautions properly, which made me very comfortable. I highly recommend them and will be returning to try out some of their other services."
              title="Jack M."
            />
          </div>
          <div className="mainCardCarouselItem">
            <ViewSectionCard
              description="I came to the Drip Gym for cool sculpting. Gill was amazing from start to finish. The facility is very clean and the staff followed covid precautions properly, which made me very comfortable. I highly recommend them and will be returning to try out some of their other services."
              title="Monalesa B."
            />
          </div>
          <div className="mainCardCarouselItem">
            <ViewSectionCard
              description="Was my first time at Drip Gym and it won’t be my last. I went to drip gym today to seek a much-needed boost which I did get. I recommend anyone who is seeking an immune boost or just wanting to work on their overall health contact Drip Gym and make an appointment. Gillayne was as professional as they come and made sure I had the perfect cocktail to fit my needs"
              title="Henry Paddington."
            />
          </div>
          <div className="mainCardCarouselItem">
            <ViewSectionCard
              description="I came to the Drip Gym for cool sculpting. Gill was amazing from start to finish. The facility is very clean and the staff followed covid precautions properly, which made me very comfortable. I highly recommend them and will be returning to try out some of their other services."
              title="Jack M."
            />
          </div>
        </Carousel>
      </div>
      {/* <div className="toggleArrow">
        <img src={ToggleArrow} alt="ToggleButton" />
      </div> */}
    </div>
  );
}
