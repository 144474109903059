// Init
import React from 'react';

// Importing Components
import Navbar from '../components/comman/Navbar';
import ContactUsSection from '../components/LandingPage/ContactUsSection';
import Footer from '../components/comman/Footer';
import LogoSection from '../components/LandingPage/LogoSection';

// Home Component
export default function ContactUs() {
  return (
    <>
      <Navbar isTrue />
      <ContactUsSection istrue />
      <LogoSection />
      <Footer />
    </>
  );
}
